<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p(v-html="exp.expDesc")
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import BackHelp from "../../../components/BackHelp";
import Footer from "../../../components/TheFooter";
export default {
  name: "CollectionCreation",
  components: { Footer, BackHelp, Navbar },
  data() {
    return {
      ContactList: [
        {
          title: "3.3 Collection creation",
          description: [
            {
              text: "1. Click the “Manage” at the top of right corner",
              imgType: true,
              img: "200.Website profile – empty profile – 5.jpg",
            },
            {
              text: "2. Select “Your listing”",
              imgType: true,
              img: "350. Manage website.jpg",
            },
            {
              text: "3. Click “+Add collection",
              imgType: true,
              img: "250.Website-listing.jpg",
            },
            {
              text: "4. Fill up the details in collection name & note (optional), click “Next’ once it done",
              imgType: true,
              img: "281.Website-create collection-2.jpg",
            },
            {
              text: "5. Select multiple or all listings and click “Add listing”",
              imgType: true,
              img: "284.Website-create collection-select-2.jpg",
            },
          ],
        },
      ],
    };
  },
};
</script>
